body,
#root,
html,
#glossar,
.content {
  min-height: calc(100vh - 75px);
}

body {
  margin-top: 75px;
  margin-bottom: -30px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--color-dark);
}

.home {
  overflow: hidden;
}

.no {
  background-color: var(--color-main);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h3 {
  font-size: calc(0.9rem + 0.6vw);
}

.css-pwcg7p-MuiCollapse-root {
  margin-top: -1px;
}

content {
  padding: 10px;
  margin: 10px;
}

#main {
  padding: 50px 30px 30px 30px;
  background-color: var(--color-background);
  width: 95%;
}

#card-light {
  background: var(--color-main);
  width: 80%;
}

.vr {
  opacity: 0;
}

hr {
  opacity: 0;
  height: 30px;
}

.intro {
  color: var(--color-main);
}

.intro-book {
  margin-top: 10px;
  color: var(--color-grey);
}

.number {
  height: 50px;
}

.icon {
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.6));
  width: 100%;
  height: 40px;
}

.template_text {
  padding-left: 30px;
  color: black;
}

.template_icon {
  width: 85px;
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.6));
  margin: 10px 0 10px 20px;
}

.no-padding {
  padding: 0;
}

p {
  margin: 0;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.a-prozess {
  color: var(--color-gold) !important;
  text-decoration: none;
}

.button-card {
  border: 1px solid var(--color-grey);
  border-radius: 25px;
  padding: 0 8px 0 8px;
  align-self: right;
}

.link-card {
  width: 250px;
}

div.left {
  float: left;
  width: 180px;
  background: none;
}

div.right {
  text-align: right;
  float: right;
  width: 150px;
  background: none;
}

.row-right {
  width: 274px;
}

.row-left {
  margin-right: -70px;
  margin-left: -30px;
}

#intro {
  margin-bottom: 30px;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root {
  background-color: var(--color-main) !important;
}

.wrapper {
  margin: 0 0 20px 0;
}

.container-left {
  margin-left: -37.5px;
}

h2 {
  margin: 0;
  font-size: 1.2rem;
  margin-bottom: 4px;
}

html {
  background-color: var(--color-background);
}

nav {
  display: flex;
  align-items: right;
  justify-content: space-between;
  background-color: var(--color-dark);
  padding: 20px 30px;
  z-index: 1000;
  position: fixed;
  width: 100%;
  margin-top: -75px;
  color: var(--color-main);
  height: 60px;
  border-radius: 0 0 0 25px;
}

#navbar li {
  list-style: none;
  padding: 0 10px;
  position: relative;
  margin-bottom: 30px;
}

#navbar li a {
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: 600;
  color: var(--color-main);
}

a:hover {
  color: black;
}

#navbar li a:hover,
#navbar li a.active {
  color: var(--color-gold);
}

#navbar li a:hover::after,
#navbar li a.active::after {
  content: "";
  width: 30%;
  height: 2px;
  background: var(--color-dark);
  position: absolute;
  bottom: -4px;
  left: 20px;
}

#infoAndHelp {
  display: flex;
  flex-direction: row;
  padding: 5px;
}

#navbar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: fixed;
  top: 55px;
  right: -320px;
  width: 280px;
  height: 100vh;
  background: var(--color-dark);
  padding: 40px 0 0 30px;
  transition: 0.3s ease-in-out;
  overflow: scroll;
  scroll-padding-top: -40px;
  z-index: 100;
}

#navbar::-webkit-scrollbar {
  display: none;
}

#navbar.active {
  right: 0px;
}

#mobile {
  display: block;
}

#mobile i {
  font-size: 24px;
  cursor: pointer;
}

.accordion {
  margin-left: -50px;
  width: 310px;
  padding-left: 20px !important;
  color: var(--color-main) !important;
}

#subnav {
  padding-top: 25px;
  padding-bottom: 5px;
  padding-left: 70px;
  padding-right: 0px;
  margin-left: -15px;
  width: 295px;
  background: var(--color-dark);
}

#subnav li {
  padding: 0px;
}

#subnav li a {
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--color-main);
}

.overlay.active {
  position: fixed;
  visibility: visible !important;
  margin-top: -70px;
  margin-left: -30px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  transition: 0.3s ease-in-out;
  z-index: 98;
}

.overlay {
  visibility: hidden;
}

#logo {
  margin-top: -5px;
  width: 40px;
  height: 40px;
}

.card-glossar {
  margin-top: 20px;
}

.card-glossar p {
  margin: 30px;
}

.card-glossar h2::first-letter {
  font-size: 1.7rem;
  font-weight: bold;
}

#search {
  width: 100%;
}

#glossar {
  padding: 30px;
  background-color: var(--color-background);
  width: 95%;
}

.cls-1 {
  fill: var(--color-grey);
}

.cls-2 {
  fill: var(--color-main);
}

.icon-div {
  background-color: var(--color-grey);
  margin-top: -10px;
  margin-bottom: -10px;
  border-radius: 15px 0 0 15px;
  box-shadow: 0px -3px 0px 0 rgb(0 0 0 / 20%) inset;
  padding: 12px !important;
  width: 70px;
}

.icon-div-book {
  margin-top: -10px;
  margin-bottom: -10px;
  padding: 12px !important;
  width: 50px;
}

.MuiAccordionSummary-expandIconWrapper {
  color: var(--color-main) !important;
}

.MuiTypography-body1 {
  color: var(--color-main) !important;
}

.align-items-start {
  width: 175px;
  padding: 7px 0 0 10px;
  color: black;
}

.align-items-start-book {
  width: 260px;
  padding: 15px 0 20px 10px;
  color: black;
  border-bottom: 1px solid black;
}

.a-prozess .align-items-center {
  padding: 0px;
}

.row {
  margin-left: 0px;
}

.number-container {
  margin-right: 15px;
}

.row-card {
  min-width: 330px;
  width: 92%;
  z-index: 9;
}

.content-card {
  width: 270px !important;
  color: white;
}

.card-link {
  margin-top: -10px;
  margin-bottom: -10px;
  padding: 4px;
}

.arrow {
  width: 100%;
}

.welcome {
  width: 100%;
  margin-bottom: 30px;
  padding-right: 40px;
}

#process-svg{
  max-width: 300px;
}

.tile {
  width: 100px;
  height: 100px;
  border-radius: 20px;
  background-color: var(--color-grey);
  padding: 10px;
  margin: 0 15px 0 0;
  box-shadow: 0px -4px 0px rgba(3, 0, 0, 0.2) inset;
}

.tile:active {
  width: 100px;
  height: 96px;
  border-radius: 20px;
  background-color: var(--color-grey);
  padding: 10px;
  margin: 2px 15px 0 0;
  box-shadow: 0px 0px 0px rgba(3, 0, 0, 0.2) inset;
}

.card_custom {
  border-radius: 15px;
  box-shadow: 1px -3px 1px 0 rgb(0 0 0 / 20%) inset;
  transition: 0.3s;
  padding: 10px 0 10px 0px;
  background: var(--color-grey);
}

.card_custom_inactive {
  border-radius: 15px;
  box-shadow: 1px -3px 1px 0 rgb(0 0 0 / 20%) inset;
  transition: 0.3s;
  padding: 10px 0 10px 0px;
  background: lightgrey;
}

.tile-icon {
  width: 100%;
  height: 40px;
}

.tile-title {
  color: var(--color-main);
  justify-content: center;
  margin-top: 9px;
}

.tiles a {
  text-decoration: none !important;
}

.App .icon-div .icon {
  height: 80px;
  width: 100%;
}

.App .link-card {
  display: none !important;
}

.content-card p {
  margin-bottom: 7px;
  line-height: 130%;
  padding-right: 20px;
}

.buttons {
  width: 100%;
}

.tiles {
  width: 360px;
  margin: 0 auto;
}

.style_between {
  height: 50px;
  width: calc(100% + 30px);
  margin-left: -30px;
  background: var(--color-background);
  border-radius: 0 0 25px 0;
  position: relative;
  z-index: 9;
}

.news_container {
  background-color: var(--color-dark);
  width: calc(100% + 31px);
  margin-left: -20px;
  margin-top: -40px;
  padding: 60px 30px 30px 30px;
  border-radius: 50px 0 0 0;
  min-height: 200px;
}

.news_style_inwards {
  background-color: var(--color-dark);
  height: 51px;
  margin-top: -50px;
  margin-left: -30px;
  width: 50px;
  border-radius: 25px 0 0 0;
}

.news_style_outwards {
  background-color: var(--color-background);
  height: 50px;
  margin-top: -21px;
  margin-left: -30px;
  width: 49px;
}

.news {
  background-color: var(--color-background);
  border-radius: 20px;
  padding: 10px;
  margin-top: -20px;
  box-shadow: 0px -4px 0px rgb(3 0 0 / 20%) inset;
}

.navbar_style_inwards {
  background-color: var(--color-dark);
  height: 39px;
  margin-top: -16px;
  width: calc(5% + 29px);
  right: 0;
  position: fixed;
}

.navbar_style_outwards {
  background-color: var(--color-background);
  height: 41px;
  margin-top: -16px;
  width: 31px;
  right: 5%;
  border-radius: 0 50px 0 0;
  position: fixed;
}

#root {
  top: -1px;
  position: relative;
}

.MuiAccordion-root {
  background-color: none !important;
}

.textContainer {
  text-align: justify;
  padding-right: 20px;
}

.citation {
  color: var(--color-gold);
}

.thumbnail {
  width: 80px;
}

.template {
  width: 91%;
  border-radius: 25px;
  background-color: var(--color-grey);
  padding: 15px;
  margin: 15px 0 30px 0;
  box-shadow: 0px 4px 0px rgba(3, 0, 0, 0.2);
  color: var(--color-main);
}

.align-items-start-template {
  width: 260px;
  padding: 15px 0 20px 10px;
  color: var(--color-main);
}

.template_description {
  margin: 10px;
  color: var(--color-main);
}
.template_format {
  margin: 10px;
  font-style: italic;
}

.template_button {
  height: 45px;
  color: var(--color-main);
  text-decoration: none;
  border-radius: 20px;
  background-color: var(--color-dark);
  padding: 10px;
  margin: 0 10px 0 0;
  box-shadow: 0px -4px 0px rgba(1, 0, 0, 0.2) inset;
}

.template_button:active {
  height: 43px;
  border-radius: 20px;
  color: var(--color-main);
  padding: 10px;
  margin: 2px 10px 0 0;
  box-shadow: 0px 0px 0px rgba(3, 0, 0, 0.2) inset;
}

.template_button:hover {
  color: var(--color-main);
}

.template_buttons {
  margin: 20px 0 0 0;
}

.prozess {
  visibility: hidden;
}

.search-results {
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0px;
  margin-top: 14px;
  background-color: var(--color-background);
  padding: 20px;
}

.searchLink a {
  color: var(--color-dark);
  text-decoration: none;
  font-size: 15pt;
}

.searchLink {
  color: grey;
  font-size: 7pt;
  margin-top: 5px;
}

#searchbar {
  margin-top: -4px;
}
