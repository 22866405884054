:root {
  --color-main: #ededed;
  --color-blue: #4fa2dd;
  --color-dark: #222e37;
  --color-red: #833e26;
  --color-gold: #b1944b;
  --color-grey: #778ba1;
  --color-background: #f9f5f2;
}

.App {
  text-align: left;
  padding-left: 20px;
  padding-top: 20px;
  width: 95%;
  background-color: var(--color-background);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
